'use client';
import React, { useEffect } from 'react';
import { useAppDispatch } from '@/lib/hooks';
import { usePathname, useRouter } from 'next/navigation';
import { getToken } from '@/utils';
import { loginAction } from '@/lib/features/userSlice';
import other from '@/api/other';
const AuthCheck: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!getToken()) {
      if (!['/login', '/forgot-password'].includes(pathname)) router.replace('/login');
    } else initData();
  }, []);

  const initData = () => {
    console.log('init data');
    dispatch(loginAction());
  };

  const getSystemData = async () => {
    try {
      const res = await other.config();
    } catch (error) {
      console.log('🚀 ~ getSystemData ~ error:', error);
    }
  };
  return null;
};

export default AuthCheck;
