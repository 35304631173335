import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clearToken } from '@/utils/index';
import { SocialMedia, TelegramLink } from '@/types';
import teacher from '@/api/teacher';

type User = {
  id: string;
  nick_name: string;
  mobile: string;
  social_media: SocialMedia[];
  excerpt: string;
  avatar: string;
  email: string;
  description: string;
  telegrams: TelegramLink[];
  cover_image_url?: string;
  slug: string;
};
type UserStoreInterface = {
  user: User | null;
  isLogin: boolean;
  isAssistant: boolean;
};

const defaultValue: UserStoreInterface = {
  user: null,
  isLogin: false,
  isAssistant: false,
};

const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: defaultValue,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loginAction.fulfilled, (state, action) => {
      // Add user to the state array
      state.user = action.payload;
      state.isLogin = true;
      if (action.payload?.role_id > 0) {
        state.isAssistant = true;
      } else {
        state.isAssistant = false;
      }
    });
    builder.addCase(logoutAction.fulfilled, (state, _action) => {
      state.user = null;
      state.isLogin = false;
      state.isAssistant = false;
      clearToken();
    });
  },
  reducers: {
    updateDetail: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
  },
});

export const loginAction = createAsyncThunk('loginAction', async () => {
  const res = await teacher.info();
  return res.data;
});

export const logoutAction = createAsyncThunk('logoutAction', async () => {
  await teacher.logout();
  return true;
});
export const { updateDetail } = loginUserSlice.actions;

export default loginUserSlice.reducer;

export type { UserStoreInterface };
