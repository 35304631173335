'use client';
import React, { useEffect } from 'react';

const InitiateSW: React.FC = () => {
  const registerSW = async () => {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        await navigator.serviceWorker.register('/sw.js', { scope: '/' });
        window.location.reload();
        return;
      }
    }
  };
  const unregisterSW = async () => {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        await registration.unregister();
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    //registerSW();
    unregisterSW();
  }, []);

  return <></>;
};

export default InitiateSW;
