import client from './httpClient';

const config = () => {
  return client.get('/api/v2/other/config');
};
const getVideoFileFrmService = (fileId: string) => {
  const data = { file_id: fileId, service: 'aliyun' };
  return client.get('/api/v2/video/open/play', data);
};

const other = { config, getVideoFileFrmService };
export default other;
