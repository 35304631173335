import { configureStore } from '@reduxjs/toolkit'
import loginUserReducer from './features/userSlice';
import configSlice from './features/configSlice';

export const makeStore = () => {
    return configureStore({
        reducer: {
            loginUser: loginUserReducer,
            config: configSlice
        }
    })
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']