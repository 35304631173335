import { JsonObject } from '@/types';
import { createSlice } from '@reduxjs/toolkit';

type ConfigStoreInterface = {
    config: JsonObject | null;
    headerTitle: string
};

const defaultValue: ConfigStoreInterface = {
    config: null,
    headerTitle: ''

};

const configSlice = createSlice({
    name: 'config',
    initialState: defaultValue,
    reducers: {
        setConfig: (state, { payload }) => {
            state.config = payload;
        },
        setHeaderTitle: (state, { payload }) => {
            state.headerTitle = payload;
        },
    },
});

export default configSlice.reducer;
export const { setConfig, setHeaderTitle } = configSlice.actions;

export type { ConfigStoreInterface };
