import { SocialMedia } from '@/types';
import client from './httpClient';

const info = () => {
  return client.get('/lecturer/api/v1/detail');
};

type UpdateDetailProps = {
  nick_name?: string;
  excerpt?: string;
  password?: string;
  description?: string;
  social_media?: SocialMedia[];
  avatar?: string;
  cover_image_url?: string;
  slug?: string;
};

const updateDetail = (data: UpdateDetailProps) => {
  return client.post('/lecturer/api/v1/detail', data);
};

const logout = () => {
  return client.post('/lecturer/api/v1/logout');
};

type VerifyMobileProps = {
  mobile: string;
  mobile_code: string;
};

const verifyMobile = (data: VerifyMobileProps) => {
  return client.post('/lecturer/api/v1/detail/verify', data);
};

type ChangeMobileProps = {
  sign: string;
} & VerifyMobileProps;
const changeMobile = (data: ChangeMobileProps) => {
  return client.post('/lecturer/api/v1/detail/mobile', data);
};

type VerifyEmailProps = {
  email: string;
  email_code: string;
};

const verifyEmail = (data: VerifyEmailProps) => {
  return client.post('/lecturer/api/v1/detail/verify-email', data);
};

type ChangeEmailProps = {
  sign: string;
} & VerifyEmailProps;
const changeEmail = (data: ChangeEmailProps) => {
  return client.post('/lecturer/api/v1/detail/email', data);
};

type VerifySlugProps = {
  slug: string;
};

const verifySlug = (data: VerifySlugProps) => {
  return client.post('/lecturer/api/v1/detail/check-slug', data);
};

const teacher = { info, logout, updateDetail, changeMobile, verifyMobile, changeEmail, verifyEmail, verifySlug };
export default teacher;
